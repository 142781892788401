import React, { Component, Fragment } from 'react';

import IsIdle from '../../primespot-ui/systems/isIdle';

class IdleTest extends Component {
  handleMouseMove = data => {
    console.log(data);
  };

  render() {
    return (
      <Fragment>
        <div
          style={{
            width: '500px',
            height: '500px',
            background: 'red',
            margin: '120px',
          }}
        />
        <IsIdle
          timeout={10 * 1000}
          onIdleStatusChange={status => {
            console.log(status);
          }}
        />
      </Fragment>
    );
  }
}

export default IdleTest;
